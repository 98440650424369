// extracted by mini-css-extract-plugin
export var search__footer = "search-module--search__footer--P1xAc";
export var search__result__item = "search-module--search__result__item--S2F8u";
export var search__searchField = "search-module--search__searchField--6k-YF";
export var search__searchField__inputContainer = "search-module--search__searchField__inputContainer--N3ARS";
export var search__searchField__submit = "search-module--search__searchField__submit--OACCF";
export var search__searchMeta = "search-module--search__searchMeta--DhKtv";
export var search__searchResults = "search-module--search__searchResults--ALnGE";
export var search__searchWrapper = "search-module--search__searchWrapper--3TKYZ";
export var search__suggestionItem = "search-module--search__suggestionItem--TDN2a";
export var search__suggestionItem__description = "search-module--search__suggestionItem__description--MIjFS";
export var search__suggestionItem__image = "search-module--search__suggestionItem__image--jS+F3";
export var search__suggestionsList = "search-module--search__suggestionsList--KAazs";
export var search__suggestionsLoader = "search-module--search__suggestionsLoader--NQ1ht";
export var search__suggestionsShowAll = "search-module--search__suggestionsShowAll--PI8s3";
export var search__suggestionsWrapper = "search-module--search__suggestionsWrapper--RfVqL";