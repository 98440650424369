import client from "../../../sanity-client-config";

const getSearch = (searchQuery, limit = 1000) => {

    const t0 = `${searchQuery}*`;
    const __types = ["news","video"];
    const __limit = limit;

    const query = `*[!(_id in path('drafts.**')) && _type in $__types&&(
        _id match $t0
        ||_type match $t0
        ||title match $t0
        ||description match $t0
        ||url match $t0
        ||slug.current match $t0
        ||pt::text(content[]) match $t0
        ||theme match $t0
        ||pt::text(bio[]) match $t0
        ||start match $t0
        ||end match $t0
        ||startEventDate match $t0
        ||name match $t0
        ||ref match $t0
        ||hreflang match $t0
        ||headers[].representationMode match $t0
        ||footers[].representationMode match $t0
        ||canonicalUrl match $t0
        ||keywords match $t0
        ||blocks[].representationMode match $t0
        ||type match $t0||accountName match $t0
        )
    ] | order(publishDate desc)[0...${__limit}]{
        _type, _id, ...select(
            _type == "news" => { "w0": _id,"w1": _type,"w2": title,"w3": slug.current,"w4": pt::text(content[]), "w5": publishDate, "w7": image.defaultImage.image, "w8": category.title.se, "w9": video.teaserVideo.url},
            _type == "video" => { "w0": _id,"w1": _type,"w2": title,"w3": slug.current,"w4": pt::text(content[]), "w5": publishDate, "w7": image.defaultImage.image, "w8": category.title.se, "w9": video.teaserVideo.url},
        )
    }`;
    return client.fetch(query, { t0, __types, __limit }).then(res => res);
}

export default getSearch;
