import React, { useRef, useState, useEffect, useContext } from 'react';
// import { navigate } from 'gatsby';
import ImageGatsby from 'gatsby-plugin-sanity-image';

import * as style from 'styles/components/search/search.module.scss';
import getSearch from 'data/queries/getSearchResults';
import ResultItem from 'components/search/Item';
import Loader from 'components/utils/Loader';
import { LangContext } from 'context/LangContext';
import enMessages from 'i18n/en.json';
import seMessages from 'i18n/se.json';

const MESSAGES = {
    en: enMessages,
    se: seMessages,
};

const Search = () => {
    const { lang } = useContext(LangContext);

    const [loading, setLoading] = useState(false);
    const [suggestLoading, setSuggestLoading] = useState(false);
    const [searchResults, setSearchResults] = useState([]);
    const [searchSuggestions, setSearchSuggestions] = useState([]);
    const [resultsPerPage, setResultsPerPage] = useState(10);
    const [queryString, setQueryString] = useState('');
    const [q, setQ] = useState('');

    const [showSuggestions, setShowSuggestions] = useState(false);
    const [isActiveInput, setIsActiveInput] = useState(false);

    const [inputValue, setInputValue] = useState('');
    const searchInput = useRef();

    const buildDate = (publishDate) => {
        const date = new Date(publishDate);
        const formattedDate = date.toLocaleDateString('sv-SE', {
            day: 'numeric',
            month: 'long',
            year: 'numeric',
        });

        return `${formattedDate}`;
    }

    const handleSearchSubmit = async (evt) => {
        evt.preventDefault();
        const searchQuery = searchInput.current?.value;
        if(searchQuery.length < 3) return;
        setLoading(true);
        setResultsPerPage(10);
        setIsActiveInput(false);
        const results = await getSearch(searchQuery);
        setQueryString(searchQuery);
        setSearchResults(results);
        setLoading(false);
    };

    const handleInputChange = async (evt) => {
        evt.preventDefault();
        const searchQuery = evt.target.value;
        setInputValue(searchQuery);

        if(searchQuery.length < 3) {
            setSearchSuggestions([]);
            setShowSuggestions(false);
            setIsActiveInput(false);
            // setLoading(true);
            return;
        }
        setIsActiveInput(true);
        setShowSuggestions(true);
        setQ(searchQuery);
        const suggestions = await getSearch(searchQuery, 11);
        setSearchSuggestions(suggestions);
        if( suggestions ) {
            setSuggestLoading(false);
        }
    };

    const handleShowMoreResults = () => {
        setResultsPerPage(resultsPerPage + 10);
    };

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const query = urlParams.get('q') !== 'null' ? urlParams.get('q') : '';
        setQueryString(query);
        setInputValue(query);
    }, []);

    useEffect(() => {
        const handleSearch = async () => {
            await handleSearchSubmit({ preventDefault: () => {} });
        }

        if (queryString) {
            handleSearch();
        }
    }, [queryString]);

    return (
        <div className={`wrapper ${style.search__searchWrapper}`}>
            <div className={style.search__searchField}>
                <form method="GET" onSubmit={handleSearchSubmit}>
                    <fieldset className={style.search__searchField__inputContainer}>
                        <input
                            type="text"
                            id="newsSearchField"
                            placeholder={MESSAGES[lang.translationKey].search.search}
                            autoComplete="off"
                            onBlur={() => {
                                setTimeout(() => {
                                    setIsActiveInput(false);
                                }, 200);
                            }}
                            ref={searchInput}
                            value={inputValue}
                            onFocus={() => setIsActiveInput(true)}
                            onChange={handleInputChange}
                        />
                    </fieldset>
                    <button type="submit" aria-label="Sök" className={style.search__searchField__submit}><i className="ico-search" aria-hidden="true" /></button>
                </form>
                {showSuggestions && isActiveInput &&
                    <div className={style.search__suggestionsWrapper}>

                        {suggestLoading &&
                            <div className={style.search__suggestionsLoader}>
                                <Loader />
                            </div>
                        }
                        {searchSuggestions.length > 0 &&
                        <ul>
                            {searchSuggestions.slice(0,10).map((suggestion, index) => (
                                <li key={index} className={style.search__suggestionItem}>
                                    <a href={`/nyheter/${suggestion.w3}`}>
                                        <div className={style.search__suggestionItem__image}>
                                            {suggestion.w7 && (
                                                <ImageGatsby
                                                    {...suggestion.w7}
                                                    width={80}
                                                    height={80}
                                                />
                                            )}
                                        </div>
                                        <div className={style.search__suggestionItem__description}>
                                            <h4 className="text-md text-semibold">{suggestion.w2}</h4>
                                            {suggestion.w5 && (
                                                <p className="text-xs text-medium text-darker uppercase">{buildDate(suggestion.w5)}</p>
                                            )}
                                        </div>
                                    </a>
                                </li>
                            ))}
                            {searchSuggestions.length > 10 &&
                                <li className={style.search__suggestionsShowAll}>
                                    <a className="cta-green-ter" target="_self" href={`/sok/?q=${q}`}>Visa alla resultat</a>
                                </li>
                            }
                        </ul>
                    }
                    </div>
                }

            </div>
            <div className={style.search__searchMeta}>
                {queryString && searchResults.length > 0 && !loading &&
                    <p>{MESSAGES[lang.translationKey].search.searchResults} {queryString} <strong>({searchResults.length})</strong></p>
                }
            </div>
            {queryString && searchResults.length === 0 && !loading &&
                <div className="center">
                    <h2 className="title-sm title-xs-mobile text-semibold">{MESSAGES[lang.translationKey].search.noResults}</h2>
                    <p>{MESSAGES[lang.translationKey].search.tryAnotherSearch}</p>
                </div>
            }
            {!loading &&
            <ul className={style.search__searchResults}>
            {searchResults.slice(0, resultsPerPage).map((searchResult, index) => (
                <li key={index}>
                    <ResultItem searchResult={searchResult} />
                </li>
            ))}
            </ul>
            }
            {loading && <div className="center"><Loader message={MESSAGES[lang.translationKey].search.searching}/></div>}

            {resultsPerPage < searchResults.length && !loading &&
                <div className={`center ${style.search__footer}`}>
                    <button type="button" className="cta-white-sec" onClick={handleShowMoreResults}>
                    {MESSAGES[lang.translationKey].search.loadMore}
                    </button>
                </div>
            }
        </div>
    );
};

export default Search;
