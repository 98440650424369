import React, { useContext } from 'react';
import ImageGatsby from 'gatsby-plugin-sanity-image';

import * as style from 'styles/components/search/item.module.scss';
import { LangContext } from 'context/LangContext';
import enMessages from 'i18n/en.json';
import seMessages from 'i18n/se.json';

const MESSAGES = {
    en: enMessages,
    se: seMessages,
};

const ResultItem = ({ searchResult }) => {
    const { lang } = useContext(LangContext);

    const buildDate = (publishDate) => {
        const date = new Date(publishDate);
        const formattedDate = date.toLocaleDateString('sv-SE', {
            day: 'numeric',
            month: 'long',
            year: 'numeric',
        });

        return `${formattedDate}`;
    }

    return (
        <a href={`/nyheter/${searchResult.w3}`}>
            <div className={style.resultItem}>

                <div className={style.resultItem__img}>
                    <div className={style.resultItem__img}>
                        {searchResult.w7 &&
                            <ImageGatsby
                                {...searchResult.w7}
                                width={200}
                                height={200}
                            />
                        }
                    </div>
                </div>
                <div className={style.resultItem__descriptionContainer}>
                    {searchResult.w5 && (
                    <p className="text-xs text-medium text-darker uppercase">{buildDate(searchResult.w5)}</p>
                    )}
                    <h3 className="text-lg text-semibold">{searchResult.w2}</h3>
                    <div className={`richtext ${style.resultItem__descriptionText}`}>
                        <p>
                            {`${searchResult.w4.substring(0, 150)}...`}
                        </p>
                    </div>
                    <a className="cta-yellow-ter" target="_self" href={`/nyheter/${searchResult.w3}`}>{MESSAGES[lang.translationKey].search.readMore}</a>
                </div>
            </div>
        </a>
    )
};

export default ResultItem;
