import React from 'react';

import * as style from 'styles/components/utils/loader.module.scss';

const Loader = ({ message }) =>
    <div className={style.loader__container}>
        <div className={style.loader__spinner} />
        {message && <p className={style.loader__message}>{message}</p>}
    </div>;

export default Loader;
